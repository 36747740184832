body {
  padding: 0;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  overflow-x: hidden;
  overflow-y: scroll;
  color: #222;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin-top: 0;
}
.no-decoration {
  text-decoration: none !important;
}
.btn-icon {
  margin-left: 0.5em;
}
.alert-wrap {
  margin: 1em 0;
  text-align: left;
}
a {
  color: #222;
}
.full-width {
  width: 100%;
}
